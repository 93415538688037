var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',{style:({ background: _vm.$vuetify.theme.themes[_vm.theme].background })},[_c('NavBar',{attrs:{"prop_username":_vm.usn}}),_c('SideBar'),_c('v-main',{staticClass:"main-container",attrs:{"id":"CalculatorPage"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"5"}},[_c('v-card-title',{staticClass:"font-weight-light"},[(!_vm.isBrokerUser)?_c('v-icon',{staticClass:"mr-4",attrs:{"large":"","color":_vm.$vuetify.theme.themes[_vm.theme].headerIcon}},[_vm._v("$calc")]):_vm._e(),_vm._v(" Pricing Calculator "),_c('v-spacer'),_c('v-card-actions',{staticClass:"pb-0"},[_c('v-text-field',{staticClass:"fscField",staticStyle:{"width":"79px"},attrs:{"id":"fscField","label":"Fuel Surcharge","dense":"","counter":false,"type":"number"},on:{"focus":_vm.validateFSC,"input":_vm.validateFSC,"blur":_vm.blurFSC},scopedSlots:_vm._u([{key:"prefix",fn:function(){return [_vm._v("$")]},proxy:true}]),model:{value:(_vm.fuelSurcharge),callback:function ($$v) {_vm.fuelSurcharge=$$v},expression:"fuelSurcharge"}})],1),_c('v-col',{attrs:{"cols":"3"}},[_c('v-card-actions',{staticClass:"mr-4"},[_c('v-autocomplete',{staticClass:"mt-n2",staticStyle:{"color":"black !important"},attrs:{"filter":_vm.customFilter,"items":_vm.searchData,"label":("Search for a " + (_vm.isBrokerUser
                                                ? 'Shipper'
                                                : 'Broker')),"hide-details":"","dense":"","item-text":"name","item-value":"id"},model:{value:(_vm.selectedTarget),callback:function ($$v) {_vm.selectedTarget=$$v},expression:"selectedTarget"}},[[_c('v-icon',{staticStyle:{"margin-right":"10px"},attrs:{"color":"blue darken-4"}},[_vm._v("mdi-lan-connect")])]],2)],1)],1),_c('v-card-actions',{staticClass:"mr-4 pb-0"},[_c('v-menu',{attrs:{"close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({staticStyle:{"width":"115px"},attrs:{"label":"Project until","prepend-icon":"mdi-calendar","readonly":"","dense":""},model:{value:(_vm.projectDateDisplay),callback:function ($$v) {_vm.projectDateDisplay=$$v},expression:"projectDateDisplay"}},'v-text-field',attrs,false),on))]}}])},[_c('v-card',[_c('v-date-picker',{attrs:{"allowed-dates":_vm.futureAllowed,"reactive":""},on:{"input":_vm.loadPage},model:{value:(_vm.projectDate),callback:function ($$v) {_vm.projectDate=$$v},expression:"projectDate"}})],1)],1)],1),_c('v-card-actions',{staticClass:"pb-0"},[_c('v-text-field',{staticClass:"volumeFilter",staticStyle:{"width":"100px"},attrs:{"label":"Volume Threshold","dense":"","counter":false,"type":"number"},on:{"blur":_vm.volumeFilterBlur,"focus":_vm.volumeFilterFocus},model:{value:(_vm.volumeThreshold),callback:function ($$v) {_vm.volumeThreshold=$$v},expression:"volumeThreshold"}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',{attrs:{"elevation":"5","height":"100%"}},[_c('v-card-title',{staticClass:"font-weight-light pr-0"},[_c('v-row',{staticClass:"ma-4 pr-0"},[_vm._v(" Calculator "),_c('v-menu',{attrs:{"content-class":"elevation-2","offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"dateRangeBtn",attrs:{"icon":"","ripple":false,"plain":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"dateRangeBtn",attrs:{"size":"20px"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('v-card',{attrs:{"elevation-0":"","width":"440px"}},[_c('v-card-text',[_vm._v(" The fields shown below span from "+_vm._s(_vm.startDate.split('-')[1])+"-"+_vm._s(_vm.startDate.split('-')[2])+"-"+_vm._s(_vm.startDate.split('-')[0])+" to "+_vm._s(_vm.curDateISO.split('-')[1])+"-"+_vm._s(_vm.curDateISO.split('-')[2])+"-"+_vm._s(_vm.curDateISO.split('-')[0])+" and are used to generate volume and spend projections. ")])],1)],1),_c('v-spacer'),_c('v-chip',{staticClass:"ml-12 mr-4",attrs:{"color":_vm.getTotalProjectedChangeColor()}},[_vm._v(" Total Projected Change: "+_vm._s(this.selectedTarget === undefined ? '--' : this.formatDollars( this.totalChange, 2 )))]),_c('v-divider',{attrs:{"vertical":""}}),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pt-0 pb-2 font-weight-light",staticStyle:{"font-size":"1rem"}},[_vm._v("Projected Aggregates "),_c('v-menu',{attrs:{"offset-y":"","content-class":"elevation-2"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                        var on = ref.on;
                                        var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"dateRangeBtn",staticStyle:{"background-color":"transparent !important","box-shadow":"0px 0px !important"},attrs:{"icon":"","ripple":false,"plain":""}},'v-btn',attrs,false),on),[_c('v-icon',{staticClass:"dateRangeBtn",attrs:{"size":"20px"}},[_vm._v("mdi-information-outline")])],1)]}}])},[_c('v-card',{attrs:{"width":"440px"}},[_c('v-card-text',[_vm._v(" These are aggregate projections from "+_vm._s(_vm.aggStartDateISO.split( '-' )[1])+"-"+_vm._s(_vm.aggStartDateISO.split( '-' )[2])+"-"+_vm._s(_vm.aggStartDateISO.split( '-' )[0])+" to "+_vm._s(_vm.projectDate.split( '-' )[1])+"-"+_vm._s(_vm.projectDate.split( '-' )[2])+"-"+_vm._s(_vm.projectDate.split( '-' )[0])+". You can adjust defaults in the settings page, or customize the projection end date above.")])],1)],1),_c('v-chip',{staticClass:"ml-4",attrs:{"color":this.theme == 'light'
                                                    ? 'blue lighten-5'
                                                    : '#454647'}},[_vm._v(" % Margin: "+_vm._s(this.avgMarginPercent))]),_c('v-chip',{staticClass:"ml-4",attrs:{"color":this.theme == 'light'
                                                    ? 'blue lighten-5'
                                                    : '#454647'}},[_vm._v(" Flat Margin: "+_vm._s(this.avgMarginFlat))]),_c('v-chip',{staticClass:"ml-4",attrs:{"color":this.theme == 'light'
                                                    ? 'blue lighten-5'
                                                    : '#454647'}},[_vm._v(" Volume: "+_vm._s(this.totalVol))])],1)],1),_c('v-divider',{attrs:{"vertical":""}}),_c('v-card',{attrs:{"flat":""}},[_c('v-card-title',{staticClass:"pb-0 pt-1 pr-0 pl-1 mt-n2 font-weight-light",staticStyle:{"font-size":"1rem"}},[_c('v-dialog',{attrs:{"max-width":"290"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"text-h5"},[_vm._v(" Export Proposal? ")]),_c('v-card-text',[_vm._v(" You have not entered an estimated fuel surcharge, so your proposal is an all in rate. Are you sure you want to export? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"red darken-1","text":""},on:{"click":function($event){_vm.dialog = false}}},[_vm._v(" No ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":_vm.createProposalExport}},[_vm._v(" Yes ")])],1)],1)],1),_c('v-menu',{attrs:{"close-on-content-click":"","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                var on = ref.on;
                                                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"icon":"","large":"","color":"#8c9eff","disabled":Object.keys(
                                                            _vm.laneCache
                                                        ).length == 0
                                                            ? true
                                                            : false}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-tray-arrow-down")])],1)]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-card',{attrs:{"elevation":"0","rounded":""}},[_c('v-list',{staticStyle:{"border-top":"1px"}},[_c('v-list-item',{on:{"click":function($event){this
                                                                .showDialogOnExport}}},[_c('v-list-item-title',[_vm._v("Proposal Export")])],1),_c('v-list-item',{on:{"click":function($event){this
                                                                .createExport}}},[_c('v-list-item-title',[_vm._v("Full Export")])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{staticClass:"pt-3",attrs:{"cols":"12"}},[_c('v-data-table',{ref:"table",staticClass:"elevation-0 calcTable pl-8 pr-8",attrs:{"headers":_vm.headers,"footer-props":{
                                        'items-per-page-options': [
                                            10, 25, 50, 100
                                        ]
                                    },"server-items-length":_vm.totalLength,"dense":"","items":_vm.lanes,"items-per-page":_vm.pageSize,"sort-by":_vm.sortColumn,"sort-desc":_vm.sortDesc,"header-props":{ sortIcon: null },"item-key":"laneId","expanded":_vm.expanded},on:{"update:options":_vm.updatePageOptions,"update:sortBy":function($event){_vm.sortColumn=$event},"update:sort-by":function($event){_vm.sortColumn=$event},"update:sortDesc":function($event){_vm.sortDesc=$event},"update:sort-desc":function($event){_vm.sortDesc=$event},"update:expanded":function($event){_vm.expanded=$event}},scopedSlots:_vm._u([{key:"item.arrow",fn:function(){return [_c('v-icon',[_vm._v("mdi-arrow-right-thin")])]},proxy:true},{key:"item.details",fn:function(ref){
                                    var item = ref.item;
return [_c('v-menu',{attrs:{"offset-y":"","nudge-bottom":"10"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                    var on = ref.on;
                                    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"primary","dark":"","x-small":"","plain":"","icon":""}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v(" mdi-list-box-outline ")])],1)]}}],null,true)},[_c('v-card',{staticClass:"pb-2"},[_c('v-card-title',{staticClass:"mb-2"},[_vm._v(" "+_vm._s(item.origin)+" to "+_vm._s(item.destination)+" - "+_vm._s(item.equipmenttype)+" ")]),_c('v-card-subtitle',{staticClass:"mb-4"},[_vm._v(" Lane History from "+_vm._s(_vm.startDate.split( '-' )[1])+"-"+_vm._s(_vm.startDate.split( '-' )[2])+"-"+_vm._s(_vm.startDate.split( '-' )[0])+" to "+_vm._s(_vm.curDateISO.split( '-' )[1])+"-"+_vm._s(_vm.curDateISO.split( '-' )[2])+"-"+_vm._s(_vm.curDateISO.split( '-' )[0])+" ")]),_c('v-card-text',[_c('v-row',_vm._l((_vm.detailsToShow(
                                                                item
                                                            )),function(value,key){return _c('v-col',{key:key,attrs:{"cols":"4"}},[(
                                                                    key ===
                                                                    'volume'
                                                                )?_c('v-row',{staticClass:"justify-center metricStyle"},[_vm._v(" "+_vm._s(_vm.fullDetailMetadata[ key ].formatter( value, 0 ))+" ")]):_c('v-row',{staticClass:"justify-center metricStyle"},[_vm._v(" "+_vm._s(_vm.fullDetailMetadata[ key ].formatter( value, 2 ))+" ")]),_c('v-row',{staticClass:"justify-center font-weight-light pb-4"},[_vm._v(" "+_vm._s(_vm.fullDetailMetadata[ key ].name)+" ")])],1)}),1)],1)],1)],1)]}},{key:"header.new_spend",fn:function(ref){
                                                                var header = ref.header;
return [_vm._v(" "+_vm._s((header.text = _vm.noFSC() ? 'Spend/L (All' + "‑" + 'In)' : 'Spend/L (W/O Fuel)'))+" ")]}},{key:"header.rpm",fn:function(ref){
                                                                var header = ref.header;
return [_vm._v(" "+_vm._s((header.text = _vm.noFSC() ? 'RPM (All' + "‑" + 'In)' : 'RPM (W/O Fuel)'))+" ")]}},{key:"header.avg_revenue",fn:function(ref){
                                        var header = ref.header;
return [_vm._v(" "+_vm._s((header.text = _vm.noFSC() ? 'Spend/L (All' + "‑" + 'In)' : 'Spend/L (W/O Fuel)'))+" ")]}},{key:"header.avg_cogs",fn:function(ref){
                                        var header = ref.header;
return [_vm._v(" "+_vm._s((header.text = _vm.noFSC() ? 'Cost/L (All' + "‑" + 'In)' : 'Cost/L (W/O Fuel)'))+" ")]}},{key:"no-data",fn:function(){return [_c('div',[_vm._v(" Select a broker from the dropdown menu above ")])]},proxy:true},{key:"header.suggest",fn:function(){return [_c('v-btn',{staticClass:"suggestBtn",style:(_vm.getAutoFixStyle(
                                                    _vm.suggestAllToggles[
                                                        _vm.pageNumber
                                                    ]
                                                )),attrs:{"elevation":"0","outlined":"","x-small":"","color":_vm.suggestAllToggles[
                                                    _vm.pageNumber
                                                ]
                                                    ? 'white'
                                                    : 'primary'},on:{"click":_vm.suggestAll}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-auto-fix")])],1)]},proxy:true},{key:"item.suggest",fn:function(ref){
                                                    var item = ref.item;
                                                    var index = ref.index;
return [_c('v-btn',{ref:'btn' + index,staticClass:"suggestBtn",style:(_vm.getAutoFixStyle(
                                                    _vm.suggested.has(
                                                        item.laneId
                                                    )
                                                )),attrs:{"elevation":"0","outlined":"","color":_vm.suggested.has(item.laneId)
                                                    ? 'white'
                                                    : 'primary',"x-small":"","plain":"","ripple":false},on:{"click":function($event){_vm.suggested.has(item.laneId)
                                                    ? _vm.unsuggest(item, index)
                                                    : _vm.suggest(item, index)}}},[_c('v-icon',{attrs:{"small":""}},[_vm._v("mdi-auto-fix")])],1)]}},{key:"item.margin",fn:function(ref){
                                                    var item = ref.item;
                                                    var index = ref.index;
return [_c('v-text-field',{staticClass:"pt-0 mb-2 inputRight",staticStyle:{"justify-self":"end","max-width":"80px","float":"right"},attrs:{"hide-details":"","dense":"","suffix":"%"},on:{"change":function($event){return _vm.processInput(
                                                    'frac',
                                                    item,
                                                    index
                                                )},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.processInput(
                                                    'frac',
                                                    item,
                                                    index
                                                )}},model:{value:(item.margin_display),callback:function ($$v) {_vm.$set(item, "margin_display", $$v)},expression:"item.margin_display"}})]}},{key:"item.new_margin",fn:function(ref){
                                                var item = ref.item;
                                                var index = ref.index;
return [_c('v-text-field',{staticClass:"pt-0 mb-2 inputRight",staticStyle:{"justify-self":"end","max-width":"80px","float":"right"},attrs:{"hide-details":"","dense":"","prefix":"$"},on:{"change":function($event){return _vm.processInput(
                                                    'flat',
                                                    item,
                                                    index
                                                )},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.processInput(
                                                    'flat',
                                                    item,
                                                    index
                                                )}},model:{value:(
                                                item.new_margin_display
                                            ),callback:function ($$v) {_vm.$set(item, "new_margin_display", $$v)},expression:"\n                                                item.new_margin_display\n                                            "}})]}},{key:"item.new_spend",fn:function(ref){
                                            var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDollars(value))+" ")]}},{key:"item.projected_savings",fn:function(ref){
                                            var value = ref.value;
return [_c('span',{staticClass:"ma-0 savingsCell"},[_vm._v(" "+_vm._s(_vm.formatDollars(value))+" ")])]}},{key:"item.expected_volume",fn:function(ref){
                                        var item = ref.item;
                                        var index = ref.index;
return [_c('v-text-field',{staticClass:"pt-0 mb-2 inputCenter",class:_vm.volChanged.has(item.laneId)
                                                    ? 'font-weight-bold'
                                                    : '',staticStyle:{"justify-self":"end","max-width":"80px","float":"right"},attrs:{"hide-details":"","dense":"","type":"number"},on:{"change":function($event){return _vm.processInput(
                                                    'vol',
                                                    item,
                                                    index
                                                )},"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }$event.preventDefault();return _vm.processInput(
                                                    'vol',
                                                    item,
                                                    index
                                                )}},model:{value:(item.expected_volume),callback:function ($$v) {_vm.$set(item, "expected_volume", $$v)},expression:"item.expected_volume"}})]}},{key:"item.total_margin",fn:function(ref){
                                                var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDollars(value))+" ")]}},{key:"item.avg_margin",fn:function(ref){
                                                var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatPercent(value, 1))+" ")]}},{key:"item.avg_margin_dollars",fn:function(ref){
                                                var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDollars(value))+" ")]}},{key:"item.avg_cogs",fn:function(ref){
                                                var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDollars(value))+" ")]}},{key:"item.avg_revenue",fn:function(ref){
                                                var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDollars(value))+" ")]}},{key:"item.avg_miles",fn:function(ref){
                                                var value = ref.value;
return [_vm._v(" "+_vm._s(_vm.formatDecimal(value, 0))+" ")]}},{key:"item.equipmenttype",fn:function(ref){
                                                var item = ref.item;
return [_vm._v(" "+_vm._s(item.shipmentmode && item.shipmentmode == 'IMDL' ? 'IMDL - ' + item.equipmenttype : item.equipmenttype)+" ")]}}])})],1)],1)],1)],1)],1)],1)],1),_c('SplashScreen',{attrs:{"is_loading":_vm.isLoading}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }