<template>
    <v-app>
        <v-app-bar
            clipped-left
            flat
            app
            dark
            color="white"
            class="px-xl-14 px-lg-14 px-md-14 hidden-sm-and-down"
        >
            <div>
                <v-img
                    alt="Truce Logo"
                    id="logo"
                    class="shrink mt-2"
                    contain
                    v-bind:src="require('../assets/Truce_Black.png')"
                    width="140"
                    style="margin-right: 120px"
                    @click="navigateToHome()"
                />
            </div>
            <v-spacer />
            <v-btn
                style="margin-left: 120px; font-size: 16px"
                class="mt-5 mb-5 dmsans font-weight-bold"
                elevation="0"
                color="black"
                @click="dialog = true"
                text
            >
                Contact Us
            </v-btn>
            <v-btn
                style="margin-left: 10px; font-size: 16px"
                class="mt-5 mb-5 dmsans font-weight-bold"
                elevation="0"
                color="black"
                text
                @click="navigateToApp()"
            >
                Sign In
            </v-btn>
        </v-app-bar>
        <v-app-bar
            flat
            app
            dark
            color="white"
            class="pa-0 ma-0 hidden-md-and-up"
        >
            <div>
                <v-img
                    alt="Truce Logo"
                    class="shrink mt-2"
                    contain
                    v-bind:src="require('../assets/Truce_Black.png')"
                    width="140"
                    @click="navigateToHome()"
                />
            </div>
        </v-app-bar>

        <v-main class="pa-0" align="center" justify="center">
            <v-dialog v-model="dialog" persistent max-width="600px">
                <v-card class="pa-6">
                    <v-card-title>
                        <span class="text-h5">Contact Us</span>
                    </v-card-title>
                    <v-card-text>
                        <v-container>
                            <v-row>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Name*"
                                        required
                                        v-model="name"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="6">
                                    <v-text-field
                                        label="Company"
                                        v-model="company"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-text-field
                                        label="Email*"
                                        required
                                        v-model="email"
                                    ></v-text-field>
                                </v-col>
                                <v-col cols="12">
                                    <v-textarea
                                        name="input-7-1"
                                        label="Comments"
                                        outlined
                                        v-model="comment"
                                    ></v-textarea>
                                </v-col>
                            </v-row>
                        </v-container>
                    </v-card-text>
                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <v-btn color="red" text @click="dialog = false">
                            Cancel
                        </v-btn>
                        <v-btn
                            color="green"
                            text
                            @click="
                                dialog = false;
                                sendEmail();
                            "
                        >
                            Submit
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-dialog>
            <section
                id="terms"
                style="
                    background-color: white !important;
                    color: black !important;
                "
            >
                <v-container
                    style="padding-top: 16vh"
                    class="px-xl-16 px-lg-16 px-md-16 px-sm-4 px-4"
                >
                    <div
                        class="text-h2 font-weight-bold text-left pb-14"
                        style="color: #0091ff"
                    >
                        Terms Of Use
                    </div>
                    <div class="text-left">
                        <p><i>Last Updated: September 23, 2021</i></p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >AGREEMENT TO TERMS</span
                            >
                            <br />
                            These Terms of Use constitute a legally binding
                            agreement made between you, as a user of or visitor
                            to truce.io (the “Site”) whether personally or on
                            behalf of an entity (“you” or “user” as the context
                            may require) and Truce Tech Inc and its affiliates,
                            ( “Truce”, "Company", “we”, “us”, or “our” as the
                            context may require), concerning your access to and
                            use of the Site, the Truce Transparency Platform,
                            any mobile application we provide, and anywhere else
                            we may interact with you (collectively, the
                            “Services”). You agree that by using the Services,
                            you have read, understood, and agreed to be bound by
                            all of these Terms of Use. WE RESERVE THE RIGHT TO
                            CHANGE THESE TERMS OF USE WITHOUT NOTICE AND FOR ANY
                            REASON, FROM TIME TO TIME IN OUR SOLE DISCRETION. IF
                            YOU DO NOT AGREE WITH THESE TERMS OF USE, PLEASE DO
                            NOT ACCESS THIS SITE OR USE OUR SERVICES.
                            <br />
                            <br />
                            You will be subject to and will be deemed to have
                            been made aware of and to have accepted, the changes
                            in any revised Terms of Use by your continued use of
                            the Services after the date such revised Terms of
                            Use are posted.
                            <br />
                            <br />
                            You may not use the Services or distribute the
                            information provided on the Services in any manner
                            whereby such use or distribution would be contrary
                            to law or regulation.
                            <br />
                            <br />
                            The Services is intended for users who are at least
                            18 years old. Persons under the age of 18 are not
                            permitted to use or otherwise register for the
                            Services.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >CONTENT OF SERVICES</span
                            >
                            <br />
                            We believe that the information contained within the
                            Services is accurate as at the date of publication,
                            however, no warranty is made as to the accuracy,
                            suitability, usefulness, reliability or completeness
                            of any such information and no liability with
                            respect to any errors or omissions (including any
                            third-party liability) is accepted by us or any of
                            our directors or employees. The use of our Services
                            and the delivery of information by us or our agents
                            through our Services or via email or other modes of
                            delivery, is at your own risk.
                            <br />
                            <br />
                            The information on provided via our Services is for
                            informational purposes only. It is not intended as
                            an offer to sell, or solicitation of an offer to
                            purchase any products or services. No content within
                            our Services should be construed as a recommendation
                            for any product or service by us or any third party.
                            Products and services offered by Truce are subject
                            to applicable laws and regulations are not available
                            in all geographic locations or to all customers. The
                            information on this Services does not include all
                            applicable terms or issues pertaining to any of
                            Truce’s product or service offerings.
                            <br />
                            <br />
                            This Services may provide referral information,
                            third party content or live links to an unaffiliated
                            third party and their websites, including companies
                            that have a relationship with us. Our decision to
                            provide referral information to a third party or to
                            provide a link from our Services to a third party
                            website is not an endorsement of the third party,
                            their products or services, or their website
                            accessed through any such links. Truce has no
                            control over the content on such third party website
                            and your access, use and reliance upon the content
                            on such third party websites is at your own risk.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >INTELLECTUAL PROPERTY RIGHTS</span
                            >
                            <br />
                            Unless otherwise indicated, the Services constitute
                            our proprietary property and all source code,
                            databases, functionality, software, website designs,
                            audio, video, text, photographs, and graphics on the
                            Services (collectively, the “Content”) and any
                            trademarks, service marks, and logos (“Marks”)
                            contained therein are owned or controlled by us or
                            licensed to us, and are protected by copyright and
                            trademark laws and various other intellectual
                            property rights and unfair competition laws of the
                            United States, international copyright laws, and
                            international conventions. The Content and the Marks
                            are provided on the Services “AS IS” for your
                            information and personal use only. Except as
                            expressly provided in these Terms of Use, no part of
                            the Services and no Content or Marks may be copied,
                            reproduced, aggregated, republished, uploaded,
                            posted, publicly displayed, encoded, translated,
                            transmitted, distributed, sold, licensed, or
                            otherwise exploited for any commercial purpose
                            whatsoever, without our express prior written
                            permission.
                            <br />
                            <br />
                            You hereby grant to Truce, for marketing,
                            advertising and promotional purposes, a limited,
                            nonexclusive, nontransferable license to use your
                            Marks designated from time to time for such use.
                            Truce shall abide by all standards you provide
                            concerning the formatting, style, and presentation
                            of your Marks, and shall make all use of your Marks
                            available to you for quality inspection. Truce shall
                            immediately discontinue or correct any use of your
                            Marks which does not conform to the aforementioned
                            standards.
                            <br />
                            <br />
                            Provided that you are eligible to use the Services,
                            you are granted a limited license to access and use
                            the Services and to download or print a copy of any
                            portion of the Content to which you have properly
                            gained access solely for your personal,
                            non-commercial use. We reserve all rights not
                            expressly granted to you in and to the Services, the
                            Content and the Marks.
                            <br />
                            <br />
                            Copyright notice: The works of authorship contained
                            on the Services, including but not limited to all
                            design, text, sound recordings, and images, are
                            owned, except as otherwise expressly stated, by
                            Truce. Except as otherwise expressly stated herein,
                            they may not be copied, transmitted, displayed,
                            performed, distributed (for compensation or
                            otherwise), licensed, altered, framed, stored for
                            subsequent use, or otherwise used in whole or in
                            part in any manner without our prior written
                            consent.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >USER REPRESENTATIONS</span
                            >
                            <br />
                            By using our Services, you represent and warrant
                            that: (1) you have the legal capacity and you agree
                            to comply with these Terms of Use; (2) you are not a
                            minor in the jurisdiction in which you reside; (3)
                            you will not access the Services through automated
                            or non-human means, whether through a bot, script,
                            or otherwise; (4) you will not use the Services for
                            any illegal or unauthorized purpose; and (5) your
                            use of the Services will not violate any applicable
                            law or regulation.
                            <br />
                            <br />
                            If you provide any information that is untrue,
                            inaccurate, not current, or incomplete, we have the
                            right to suspend or terminate your account and
                            refuse any and all current or future use of the
                            Services (or any portion thereof).
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >PROHIBITED ACTIVITIES</span
                            >
                            <br />
                            You may not access or use the Services for any
                            purpose other than that for which we make the
                            Services available. The Services may not be used in
                            connection with any commercial endeavors except
                            those that are specifically endorsed or approved by
                            us.
                            <br />
                            <br />
                            As a user of the Services, you agree not to:
                            <br />
                            <br />
                            1. Systematically retrieve data or other content
                            from the Services to create or compile, directly or
                            indirectly, a collection, compilation, database, or
                            directory without written permission from us.
                            <br />
                            <br />
                            2. Circumvent, disable, or otherwise interfere with
                            security-related features of the Services. Upload or
                            transmit (or attempt to upload or to transmit)
                            viruses, Trojan horses, or other material, including
                            excessive use of capital letters and spamming
                            (continuous posting of repetitive text), that
                            interferes with any party’s uninterrupted use and
                            enjoyment of the Services or modifies, impairs,
                            disrupts, alters, or interferes with the use,
                            features, functions, operation, or maintenance of
                            the Services.
                            <br />
                            <br />
                            3. Engage in any automated use of the system, such
                            as using scripts to send comments or messages, or
                            using any data mining, robots, or similar data
                            gathering and extraction tools.
                            <br />
                            <br />
                            4. Upload or transmit (or attempt to upload or to
                            transmit) any material that acts as a passive or
                            active information collection or transmission
                            mechanism, including without limitation, clear
                            graphics interchange formats (“gifs”), 1×1 pixels,
                            web bugs, cookies, or other similar devices
                            (sometimes referred to as “spyware” or “passive
                            collection mechanisms” or “pcms”).
                            <br />
                            <br />
                            5. Interfere with, disrupt, or create an undue
                            burden on the Services or the networks or services
                            connected to the Services.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >SERVICES MANAGEMENT</span
                            >
                            <br />
                            We reserve the right, but not the obligation, to:
                            (1) monitor the Services for violations of these
                            Terms of Use; (2) take appropriate legal action
                            against anyone who, in our sole discretion, violates
                            the law or these Terms of Use, including without
                            limitation, reporting such user to law enforcement
                            authorities; (3) in our sole discretion and without
                            limitation, refuse, restrict access to, limit the
                            availability of, or disable (to the extent
                            technologically feasible) your access to the
                            Services any portion thereof; (4) in our sole
                            discretion and without limitation, notice, or
                            liability, to remove from the Services or otherwise
                            disable all files and content that are excessive in
                            size or are in any way burdensome to our systems;
                            and (5) otherwise manage the Services in a manner
                            designed to protect our rights and property and to
                            facilitate the proper functioning of the Services.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >PRIVACY POLICY</span
                            >
                            <br />
                            We care about data privacy and security. By using
                            the Services, you agree to be bound by our Privacy
                            Policy posted on the Services
                            <a href="/privacy-policy"
                                >www.truce.io/privacy-policy</a
                            >, which is incorporated into these Terms of Use.
                            Please be advised the Services are hosted in the
                            United States. If you access the Services from any
                            other region of the world with laws or other
                            requirements governing personal data collection,
                            use, or disclosure that differ from applicable laws
                            in the United States, then through your continued
                            use of the Services, you are transferring your data
                            to the United States, and you agree to have your
                            data transferred to and processed in the United
                            States.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >DATA SECURITY AND INTEGRITY</span
                            >
                            <br />
                            We will maintain certain data that you transmit to
                            the Services for the purpose of managing the
                            performance of the Services, as well as data
                            relating to your use of the Services. Although we
                            perform regular routine backups of data, you are
                            solely responsible for all data that you transmit or
                            that relates to any activity you have undertaken
                            using the Services. You agree that we shall have no
                            liability to you for any loss or corruption of any
                            such data, and you hereby waive any right of action
                            against us arising from any such loss or corruption
                            of such data. You and the Services agree that we may
                            access, store, process, and use any information and
                            personal data that you provide following the terms
                            of the Privacy Policy and your choices (including
                            settings).
                            <br />
                            <br />
                            As part of a user’s access to Truce’s Transparency
                            Platform or other Services, user will provide User
                            Data as defined in Truce’s Privacy Policy
                            <a href="/privacy-policy"
                                >www.truce.io/privacy-policy</a
                            >. Users represent and warrant that any User Data
                            submitted is accurate and upon reasonable notice,
                            Truce may conduct technical, financial, and
                            operational audits to ensure the accuracy of this
                            User Data. Truce will maintain this data in
                            confidence, and implement, maintain, and test
                            controls reasonably designed to ensure the security
                            and integrity of system on or through which User
                            Data is stored, accessed, processed or transmitted.
                            <br />
                            <br />
                            You hereby grant Truce a non-exclusive,
                            non-perpetual, revocable, non-sublicensable, limited
                            right and license to User Data solely for Truce to
                            provide Services and for other relevant commercial
                            purposes. User Data will be anonymized and Truce
                            reserves all rights in and ownership of this
                            anonymized User Data (with the exception of the
                            Broker Name and Shipper Name data fields) and you
                            will not disclose or provide access to this User
                            Data to any of your competitors. You also agree that
                            you will not share any broker or shipping data with
                            competitors and the prohibition on sharing any data
                            or information with competitors will supersede any
                            provision in any confidentiality, non-disclosure or
                            other agreement entered into in conjunction with
                            your use of our Services. Truce will not attempt to
                            identify or re-identify any person or entity whose
                            information may be included in any anonymized or
                            otherwise aggregated or de-identified data received
                            or processed as part of your use of the Services.
                            <br />
                            <br />
                            Subject to the requirements of these Terms of Use,
                            Truce will delete, destroy, or otherwise remove from
                            the platform the specific fields or data points that
                            make up your User Data after the date you leave or
                            otherwise terminate your access to our Services.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >TERM AND TERMINATION</span
                            >
                            <br />
                            These Terms of Use shall remain in full force and
                            effect while you use the Services. WITHOUT LIMITING
                            ANY OTHER PROVISION OF THESE TERMS OF USE, WE
                            RESERVE THE RIGHT TO, IN OUR SOLE DISCRETION AND
                            WITHOUT NOTICE OR LIABILITY, DENY ACCESS TO AND USE
                            OF THE SERVICES (INCLUDING BLOCKING CERTAIN IP
                            ADDRESSES), TO ANY PERSON FOR ANY REASON OR FOR NO
                            REASON, INCLUDING WITHOUT LIMITATION FOR BREACH OF
                            ANY REPRESENTATION, WARRANTY, OR COVENANT CONTAINED
                            IN THESE TERMS OF USE OR OF ANY APPLICABLE LAW OR
                            REGULATION. WE MAY TERMINATE YOUR USE OR
                            PARTICIPATION IN THE SERVICES OR DELETE ANY CONTENT
                            OR INFORMATION THAT YOU POSTED AT ANY TIME, WITHOUT
                            WARNING, IN OUR SOLE DISCRETION.
                            <br />
                            <br />
                            In addition to terminating or suspending your
                            account, we reserve the right to take appropriate
                            legal action, including without limitation pursuing
                            civil, criminal, and injunctive redress.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >MODIFICATIONS AND INTERRUPTIONS</span
                            >
                            <br />
                            We reserve the right to change, modify, or remove
                            the contents of the Services at any time or for any
                            reason at our sole discretion without notice.
                            However, we have no obligation to update any
                            information on our Services. We also reserve the
                            right to modify or discontinue all or part of the
                            Services without notice at any time. We will not be
                            liable to you or any third party for any
                            modification, price change, suspension, or
                            discontinuance of the Services.
                            <br />
                            <br />
                            We cannot guarantee the Services will be available
                            at all times. We may experience hardware, software,
                            or other problems or need to perform maintenance
                            related to the Services, resulting in interruptions,
                            delays, or errors. We reserve the right to change,
                            revise, update, suspend, discontinue, or otherwise
                            modify the Services at any time or for any reason
                            without notice to you. You agree that we have no
                            liability whatsoever for any loss, damage, or
                            inconvenience caused by your inability to access or
                            use the Services during any downtime or
                            discontinuance of the Services. Nothing in these
                            Terms of Use will be construed to obligate us to
                            maintain and support the Services or to supply any
                            corrections, updates, or releases in connection
                            therewith.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >GOVERNING LAW</span
                            >
                            <br />
                            These Terms of Use and your use of the Services are
                            governed by and construed in accordance with the
                            laws of the State of Illinois applicable to
                            agreements made and to be entirely performed within
                            the State of Illinois, without regard to its
                            conflict of law principles.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >CORRECTIONS</span
                            >
                            <br />
                            There may be information within the Services that
                            contains typographical errors, inaccuracies, or
                            omissions, including descriptions, pricing,
                            availability, and various other information. We
                            reserve the right to correct any errors,
                            inaccuracies, or omissions and to change or update
                            the information on the Services at any time, without
                            prior notice.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >DISCLAIMER; LIMITATION OF LIABILITY</span
                            >
                            <br />
                            THE SERVICES ARE PROVIDED ON AN AS-IS AND
                            AS-AVAILABLE BASIS. YOU AGREE THAT YOUR USE OF THE
                            SERVICES AND OUR SERVICES WILL BE AT YOUR SOLE RISK.
                            TO THE FULLEST EXTENT PERMITTED BY LAW, WE DISCLAIM
                            ALL WARRANTIES, EXPRESS OR IMPLIED, IN CONNECTION
                            WITH THE SERVICES AND YOUR USE THEREOF, INCLUDING,
                            WITHOUT LIMITATION, THE IMPLIED WARRANTIES OF
                            MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE,
                            AND NON-INFRINGEMENT. WE MAKE NO WARRANTIES OR
                            REPRESENTATIONS ABOUT THE ACCURACY OR COMPLETENESS
                            OF THE SERVICES’S CONTENT OR THE CONTENT OF ANY
                            WEBSERVICESS LINKED TO THE SERVICES AND WE WILL
                            ASSUME NO LIABILITY OR RESPONSIBILITY FOR ANY (1)
                            ERRORS, MISTAKES, DELAYS, OR INACCURACIES OF CONTENT
                            AND MATERIALS, (2) PERSONAL INJURY OR PROPERTY
                            DAMAGE, OF ANY NATURE WHATSOEVER, RESULTING FROM
                            YOUR ACCESS TO AND USE OF THE SERVICES, (3) ANY
                            UNAUTHORIZED ACCESS TO OR USE OF OUR SECURE SERVERS
                            AND/OR ANY AND ALL PERSONAL INFORMATION AND/OR
                            FINANCIAL INFORMATION STORED THEREIN, (4) ANY
                            INTERRUPTION OR CESSATION OF TRANSMISSION TO OR FROM
                            THE SERVICES, (5) ANY BUGS, VIRUSES, TROJAN HORSES,
                            OR OTHER MALICIOUS CONTENT WHICH MAY BE TRANSMITTED
                            TO OR THROUGH THE SERVICES BY ANY THIRD PARTY,
                            AND/OR (6) ANY ERRORS OR OMISSIONS IN ANY CONTENT
                            AND MATERIALS OR FOR ANY LOSS OR DAMAGE OF ANY KIND
                            INCURRED AS A RESULT OF THE USE OF ANY CONTENT
                            POSTED, TRANSMITTED, OR OTHERWISE MADE AVAILABLE VIA
                            THE SERVICES. WE DO NOT WARRANT, ENDORSE, GUARANTEE,
                            OR ASSUME RESPONSIBILITY FOR ANY PRODUCT OR SERVICE
                            ADVERTISED OR OFFERED BY A THIRD PARTY THROUGH THE
                            SERVICES, ANY HYPERLINKED WEBSERVICES, OR ANY
                            WEBSERVICES OR MOBILE APPLICATION FEATURED IN ANY
                            BANNER OR OTHER ADVERTISING, AND WE WILL NOT BE A
                            PARTY TO OR IN ANY WAY BE RESPONSIBLE FOR MONITORING
                            ANY TRANSACTION BETWEEN YOU AND ANY THIRD-PARTY
                            PROVIDERS OF PRODUCTS OR SERVICES. AS WITH THE
                            PURCHASE OF A PRODUCT OR SERVICE THROUGH ANY MEDIUM
                            OR IN ANY ENVIRONMENT, YOU SHOULD USE YOUR BEST
                            JUDGMENT AND EXERCISE CAUTION WHERE APPROPRIATE.
                            <br />
                            <br />
                            IN NO EVENT WILL WE OR OUR DIRECTORS, EMPLOYEES, OR
                            AGENTS BE LIABLE TO YOU OR ANY THIRD PARTY FOR ANY
                            INDIRECT, CONSEQUENTIAL, EXEMPLARY, INCIDENTAL,
                            SPECIAL, OR PUNITIVE DAMAGES, INCLUDING LOST PROFIT,
                            LOST REVENUE, LOSS OF DATA, OR OTHER DAMAGES ARISING
                            FROM YOUR USE OF THE SERVICES OR ANY PORTION
                            THEREOF, REGARDLESS OF WHETHER WE HAVE BEEN APPRISED
                            OF THE LIKELIHOOD OF SUCH DAMAGES OCCURRING AND
                            REGARDLESS OF THE FORM OF ACTION, WHETHER IN
                            CONTRACT, WARRANTY, TORT, STRICT LIABILITY, OR
                            OTHERWISE.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >INDEMNIFICATION</span
                            >
                            <br />
                            You agree to defend, indemnify, and hold us
                            harmless, including our subsidiaries, affiliates,
                            and all of our respective officers, agents,
                            partners, and employees, from and against any loss,
                            damage, liability, claim, or demand, including
                            reasonable attorneys’ fees and expenses, made by any
                            third party due to or arising out of: (1) use of the
                            Services; (2) breach of these Terms of Use; (3) any
                            breach of your representations and warranties set
                            forth in these Terms of Use; or (4) your violation
                            of the rights of a third party, including but not
                            limited to intellectual property rights.
                            Notwithstanding the foregoing, we reserve the right,
                            at your expense, to assume the exclusive defense and
                            control of any matter for which you are required to
                            indemnify us, and you agree to cooperate, at your
                            expense, with our defense of such claims. We will
                            use reasonable efforts to notify you of any such
                            claim, action, or proceeding which is subject to
                            this indemnification upon becoming aware of it.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >ELECTRONIC COMMUNICATIONS, TRANSACTIONS, AND
                                SIGNATURES</span
                            >
                            <br />
                            Using the Services, sending us emails, and
                            completing online forms constitute electronic
                            communications. You consent to receive electronic
                            communications, and you agree that all agreements,
                            notices, disclosures, and other communications we
                            provide to you electronically, via email and on the
                            Services, satisfy any legal requirement that such
                            communication be in writing. YOU HEREBY AGREE TO THE
                            USE OF ELECTRONIC SIGNATURES, CONTRACTS, ORDERS, AND
                            OTHER RECORDS, AND TO ELECTRONIC DELIVERY OF
                            NOTICES, POLICIES, AND RECORDS OF TRANSACTIONS
                            INITIATED OR COMPLETED BY US OR VIA THE SERVICES.
                            You hereby waive any rights or requirements under
                            any statutes, regulations, rules, ordinances, or
                            other laws in any jurisdiction which require an
                            original signature or delivery or retention of
                            non-electronic records, or to payments or the
                            granting of credits by any means other than
                            electronic means.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >CALIFORNIA USERS AND RESIDENTS</span
                            >
                            <br />
                            If any complaint with us is not satisfactorily
                            resolved, you can contact the Complaint Assistance
                            Unit of the Division of Consumer Services of the
                            California Department of Consumer Affairs in writing
                            at 1625 North Market Blvd., Suite N 112, Sacramento,
                            California 95834 or by telephone at (800) 952-5210
                            or (916) 445-1254.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >MISCELLANEOUS</span
                            >
                            <br />
                            These Terms of Use and any policies or operating
                            rules posted by us on the Services or in respect to
                            the Services constitute the entire agreement and
                            understanding between you and us. Our failure to
                            exercise or enforce any right or provision of these
                            Terms of Use shall not operate as a waiver of such
                            right or provision. These Terms of Use operate to
                            the fullest extent permissible by law. We may assign
                            any or all of our rights and obligations to others
                            at any time. We shall not be responsible or liable
                            for any loss, damage, delay, or failure to act
                            caused by any cause beyond our reasonable control.
                            If any provision or part of a provision of these
                            Terms of Use is determined to be unlawful, void, or
                            unenforceable, that provision or part of the
                            provision is deemed severable from these Terms of
                            Use and does not affect the validity and
                            enforceability of any remaining provisions. There is
                            no joint venture, partnership, employment or agency
                            relationship created between you and us as a result
                            of these Terms of Use or use of the Services. You
                            agree that these Terms of Use will not be construed
                            against us by virtue of having drafted them. You
                            hereby waive any and all defenses you may have based
                            on the electronic form of these Terms of Use and the
                            lack of signing by the parties hereto to execute
                            these Terms of Use.
                        </p>
                        <p>
                            <span class="text-h6 font-weight-bold"
                                >CONTACT US</span
                            >
                            <br />
                            In order to resolve a complaint regarding the
                            Services or to receive further information regarding
                            use of the Services, please contact us at
                            contact@truce.io.
                        </p>
                    </div>
                </v-container>
            </section>

            <v-footer style="background-color: #0d47a1">
                <v-card
                    flat
                    tile
                    width="100%"
                    class="blue darken-4 text-center hidden-sm-and-down"
                >
                    <v-card-text>
                        <v-row>
                            <v-col cols="6">
                                <v-row>
                                    <div>
                                        <v-img
                                            alt="Truce Logo"
                                            class="shrink"
                                            contain
                                            src="../assets/Truce_Logo_White.png"
                                            width="140"
                                            style="margin-right: 120px"
                                        />
                                    </div>
                                </v-row>
                                <v-row>
                                    <div class="white--text">
                                        {{ `&copy; ${getCopyrightString()}` }}
                                    </div>
                                </v-row>
                            </v-col>
                            <v-col cols="6" class="text-right">
                                <v-btn
                                    style="margin-left: 120px; font-size: 16px"
                                    class="mt-5 dmsans font-weight-bold"
                                    elevation="0"
                                    color="white"
                                    text
                                    @click="navigateToPrivacy()"
                                >
                                    Privacy
                                </v-btn>
                                <v-btn
                                    style="margin-left: 10px; font-size: 16px"
                                    class="mt-5 dmsans font-weight-bold"
                                    elevation="0"
                                    color="white"
                                    text
                                    @click="toTop()"
                                >
                                    Terms of Use
                                </v-btn>
                            </v-col>
                        </v-row>
                    </v-card-text>
                </v-card>
                <v-row class="hidden-md-and-up">
                    <v-col cols="12">
                        <div class="mx-auto text-center">
                            <v-btn
                                style="font-size: 16px"
                                class="mt-5 dmsans font-weight-bold"
                                elevation="0"
                                color="white"
                                text
                                @click="navigateToPrivacy()"
                            >
                                Privacy
                            </v-btn>
                        </div>
                        <div class="mx-auto text-center">
                            <v-btn
                                style="font-size: 16px"
                                class="mb-5 dmsans font-weight-bold"
                                elevation="0"
                                color="white"
                                text
                                @click="toTop()"
                            >
                                Terms of Use
                            </v-btn>
                            <div class="white--text">
                                {{ `&copy; ${getCopyrightString()}` }}
                            </div>
                        </div>
                    </v-col>
                </v-row>
            </v-footer>
        </v-main>
    </v-app>
</template>

<script>
import { API } from 'aws-amplify';
import * as utils from '../utils';
export default {
    name: 'Landing',
    data: function () {
        return {
            scrollPosition: null,
            btnColor_1: 'white',
            logo_img: 'Truce_Logo_White.png',
            dialog: false,
            name: '',
            company: '',
            email: '',
            comment: '',
            backToTop: false,
            backToTopMargin: 'backToTop'
        };
    },
    methods: {
        getCopyrightString: utils.getCopyrightString,
        updateScroll(e) {
            this.scrollPosition = window.scrollY;
            if (
                this.scrollPosition >
                document.documentElement.clientHeight - 50
            ) {
                this.btnColor_1 = 'black';
                this.logo_img = 'Truce_Logo_Black.png';
            } else {
                this.btnColor_1 = 'white';
                this.logo_img = 'Truce_Logo_White.png';
            }

            if (this.scrollPosition < window.innerHeight * 5 - 50) {
                this.backToTopMargin = 'backToTop';
            } else {
                this.backToTopMargin = 'backToTop_Footer';
            }

            if (typeof window === 'undefined') return;
            const top = window.pageYOffset || e.target.scrollTop || 0;
            this.backToTop = top > 20;
        },
        navigateToApp() {
            this.$router.push({
                name: 'app',
                params: { prop_usn: 'Roop Pal', prop_toggle: 'left' }
            });
        },
        navigateToHome() {
            this.$router.push({
                name: 'landing'
            });
        },
        navigateToPrivacy() {
            this.$router.push({
                name: 'privacy'
            });
        },

        sendEmail() {
            const apiName = 'Contact'; // replace this with your api name.
            const path = '/contact'; //replace this with the path you have configured on your API
            const myInit = {
                body: {
                    name: this.name,
                    company: this.company,
                    email: this.email,
                    comment: this.comment
                }, // replace this with attributes you need
                headers: {} // OPTIONAL
            };

            API.post(apiName, path, myInit)
                .then((response) => {
                    console.log('DONE SENDING EMAIL');
                })
                .catch((error) => {
                    console.log(error.response);
                });
        },
        toTop() {
            this.$vuetify.goTo(0);
        }
    },
    beforeMount() {
        this.$vuetify.goTo(0);
    },
    mounted() {
        //window.addEventListener("scroll", this.updateScroll);
        this.$vuetify.goTo(0);
    }
};
</script>
<style lang="scss" scoped>
@import url('https://fonts.googleapis.com/css2?family=DM+Sans:wght@400;700&display=swap');
.v-tab {
    text-transform: none !important;
}

.v-tab:hover {
    color: #536dfe !important;
    border-bottom: 2px solid #536dfe !important;
}

.rounded-card {
    border-radius: 5px !important;
}

.my-overlay >>> .v-overlay__content {
    width: 100%;
}

.change_color {
    color: #536dfe;
}

.bottom-gradient {
    background-image: linear-gradient(to bottom, rgb(255, 0, 0) 0%, 72px);
}

.dmsans {
    font-family: 'DM Sans', sans-serif !important;
}
.backToTop {
    margin-bottom: 4.3vh;
}
.backToTop_Footer {
    margin-bottom: 17vh;
}

#logo:hover {
    cursor: pointer;
}
</style>
