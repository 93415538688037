<template>
    <v-app :style="{ background: $vuetify.theme.themes[theme].background }">
        <NavBar :prop_username="usn" />
        <SideBar />
        <v-main class="main-container">
            <v-container fluid style="width: 75%">
                <v-snackbar
                    v-model="snackbar"
                    :timeout="snackBarTimeout"
                    :centered="true"
                >
                    {{ snackbarText }}

                    <template v-slot:action="{ attrs }">
                        <v-btn
                            color="blue"
                            text
                            v-bind="attrs"
                            @click="snackbar = false"
                        >
                            Close
                        </v-btn>
                    </template>
                </v-snackbar>
                <v-row>
                    <v-col cols="12">
                        <v-card height="35rem" :loading="isLoading">
                            <v-card-actions>
                                <v-row>
                                    <v-col cols="2" style="height: 35rem">
                                        <v-navigation-drawer permanent>
                                            <v-list>
                                                <v-list-item>
                                                    <v-list-item-avatar>
                                                        <v-avatar
                                                            color="primary"
                                                        >
                                                            <span
                                                                class="white--text text-h6"
                                                            >
                                                                {{ shortName }}
                                                            </span>
                                                        </v-avatar>
                                                    </v-list-item-avatar>
                                                </v-list-item>

                                                <v-list-item>
                                                    <v-list-item-content>
                                                        <v-list-item-title
                                                            class="text-h6"
                                                        >
                                                            {{
                                                                firstName +
                                                                ' ' +
                                                                lastName
                                                            }}
                                                        </v-list-item-title>
                                                        <v-list-item-subtitle
                                                            >{{ email }}
                                                        </v-list-item-subtitle>
                                                    </v-list-item-content>
                                                </v-list-item>
                                            </v-list>
                                            <v-divider></v-divider>
                                            <v-list nav dense>
                                                <v-list-item-group
                                                    v-model="selectedItem"
                                                    color="primary"
                                                    mandatory
                                                >
                                                    <v-list-item
                                                        v-for="(
                                                            item, i
                                                        ) in items"
                                                        :key="i"
                                                    >
                                                        <v-list-item-icon>
                                                            <v-icon
                                                                v-text="
                                                                    item.icon
                                                                "
                                                            ></v-icon>
                                                        </v-list-item-icon>

                                                        <v-list-item-content>
                                                            <v-list-item-title
                                                                v-text="
                                                                    item.text
                                                                "
                                                            ></v-list-item-title>
                                                        </v-list-item-content>
                                                    </v-list-item>
                                                </v-list-item-group>
                                            </v-list>
                                        </v-navigation-drawer>
                                    </v-col>

                                    <v-col cols="10" style="height: 35rem">
                                        <template v-if="selectedItem == 0">
                                            <v-card elevation="0">
                                                <v-card-title
                                                    class="text-h5 pt-6"
                                                    >Profile</v-card-title
                                                >
                                                <v-card-text>
                                                    <v-row class="pt-8">
                                                        <v-col
                                                            cols="3"
                                                            align-self="center"
                                                            class="pl-8"
                                                        >
                                                            <v-subheader
                                                                >First Name:
                                                            </v-subheader>
                                                        </v-col>
                                                        <v-col cols="7">
                                                            <v-text-field
                                                                :value="
                                                                    firstName
                                                                "
                                                                disabled
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col
                                                            cols="3"
                                                            align-self="center"
                                                            class="pl-8"
                                                        >
                                                            <v-subheader
                                                                >Last Name:
                                                            </v-subheader>
                                                        </v-col>
                                                        <v-col cols="7">
                                                            <v-text-field
                                                                :value.sync="
                                                                    lastName
                                                                "
                                                                disabled
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col
                                                            cols="3"
                                                            align-self="center"
                                                            class="pl-8"
                                                        >
                                                            <v-subheader
                                                                >Email:
                                                            </v-subheader>
                                                        </v-col>
                                                        <v-col cols="7">
                                                            <v-text-field
                                                                :value="email"
                                                                disabled
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>

                                                    <v-row>
                                                        <v-col
                                                            cols="3"
                                                            align-self="center"
                                                            class="pl-8"
                                                        >
                                                            <v-subheader
                                                                >Phone Number:
                                                            </v-subheader>
                                                        </v-col>
                                                        <v-col cols="7">
                                                            <v-text-field
                                                                :value="
                                                                    phoneNumber
                                                                "
                                                                disabled
                                                            ></v-text-field>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </template>
                                        <template v-if="selectedItem == 1">
                                            <v-card elevation="0">
                                                <v-card-title
                                                    class="text-h5 pt-6"
                                                >
                                                    Settings
                                                    <v-spacer> </v-spacer>
                                                    <v-switch
                                                        v-model="
                                                            formFields.darkMode
                                                        "
                                                        inset
                                                        :label="`${
                                                            formFields.darkMode
                                                                ? 'Disable'
                                                                : 'Enable'
                                                        } Dark Mode`"
                                                        @change="
                                                            toggleDarkMode()
                                                        "
                                                    ></v-switch>
                                                </v-card-title>
                                                <v-card-text>
                                                    <v-row class="pt-4">
                                                        <v-col
                                                            cols="3"
                                                            align-self="center"
                                                            class="pl-8 text-right"
                                                        >
                                                            <v-subheader
                                                                class="px-0"
                                                                >Aggregate Start
                                                                Date:
                                                            </v-subheader>
                                                        </v-col>
                                                        <v-col cols="7">
                                                            <v-menu
                                                                ref="menu1"
                                                                v-model="menu1"
                                                                :close-on-content-click="
                                                                    false
                                                                "
                                                                transition="scale-transition"
                                                                offset-y
                                                                min-width="auto"
                                                            >
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-text-field
                                                                        v-model="
                                                                            formFields.aggregationStartDate
                                                                        "
                                                                        label="Select Date"
                                                                        prepend-icon="mdi-calendar"
                                                                        readonly
                                                                        v-bind="
                                                                            attrs
                                                                        "
                                                                        v-on="
                                                                            on
                                                                        "
                                                                    ></v-text-field>
                                                                </template>
                                                                <v-date-picker
                                                                    v-model="
                                                                        formFields.aggregationStartDate
                                                                    "
                                                                    no-title
                                                                    scrollable
                                                                    :allowed-dates="
                                                                        allowedDates
                                                                    "
                                                                    reactive
                                                                >
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                                    <v-row>
                                                        <v-col
                                                            cols="3"
                                                            align-self="center"
                                                            class="pl-8 text-right"
                                                        >
                                                            <v-subheader
                                                                class="px-0"
                                                                >Projection End
                                                                Date:
                                                            </v-subheader>
                                                        </v-col>
                                                        <v-col cols="7">
                                                            <v-menu
                                                                ref="menu2"
                                                                v-model="menu2"
                                                                :close-on-content-click="
                                                                    false
                                                                "
                                                                transition="scale-transition"
                                                                offset-y
                                                                min-width="auto"
                                                            >
                                                                <template
                                                                    v-slot:activator="{
                                                                        on,
                                                                        attrs
                                                                    }"
                                                                >
                                                                    <v-text-field
                                                                        v-model="
                                                                            formFields.defaultProjectionDate
                                                                        "
                                                                        label="Select Date"
                                                                        prepend-icon="mdi-calendar"
                                                                        readonly
                                                                        v-bind="
                                                                            attrs
                                                                        "
                                                                        v-on="
                                                                            on
                                                                        "
                                                                    ></v-text-field>
                                                                </template>
                                                                <v-date-picker
                                                                    v-model="
                                                                        formFields.defaultProjectionDate
                                                                    "
                                                                    no-title
                                                                    scrollable
                                                                    :allowed-dates="
                                                                        futureOnly
                                                                    "
                                                                    reactive
                                                                >
                                                                </v-date-picker>
                                                            </v-menu>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                                <v-card-actions>
                                                    <v-row>
                                                        <v-col cols="9"></v-col>
                                                        <v-col cols="2">
                                                            <v-btn
                                                                color="green"
                                                                text
                                                                @click="
                                                                    saveChanges
                                                                "
                                                            >
                                                                Save
                                                            </v-btn>
                                                        </v-col>
                                                        <v-col cols="1"></v-col>
                                                    </v-row>
                                                </v-card-actions>
                                            </v-card>
                                        </template>
                                        <template v-if="selectedItem == 2">
                                            <v-card elevation="0">
                                                <v-card-title
                                                    class="text-h5 pt-6"
                                                    >Notification
                                                    Settings</v-card-title
                                                >
                                                <v-card-text>
                                                    <v-row>
                                                        <v-col cols="12">
                                                            <v-card
                                                                elevation="0"
                                                            >
                                                                <v-card-text>
                                                                    <v-simple-table
                                                                        class="notificationDataTable"
                                                                    >
                                                                        <template
                                                                            v-slot:default
                                                                        >
                                                                            <tbody>
                                                                                <tr
                                                                                    v-for="item in switches"
                                                                                    :key="
                                                                                        item.name
                                                                                    "
                                                                                >
                                                                                    <td>
                                                                                        {{
                                                                                            item.name
                                                                                        }}
                                                                                    </td>
                                                                                    <td>
                                                                                        {{
                                                                                            item.description
                                                                                        }}
                                                                                    </td>
                                                                                    <!-- <td style="max-width: 15rem">
                                            <v-select
                                              v-model="item.frequency_model"
                                              :items="days"
                                              multiple
                                              @blur="updateSubscriptionFrequency(item)"
                                            ></v-select>
                                          </td> -->
                                                                                    <td>
                                                                                        <v-switch
                                                                                            color="green"
                                                                                            inset
                                                                                            label="Subscribed"
                                                                                            v-model="
                                                                                                item.subscribed
                                                                                            "
                                                                                            :value="
                                                                                                item.subscribed
                                                                                            "
                                                                                            @change="
                                                                                                modifySubscriptionStatus(
                                                                                                    item.value,
                                                                                                    item.subscribed
                                                                                                )
                                                                                            "
                                                                                            :disabled="
                                                                                                isLoading
                                                                                            "
                                                                                        ></v-switch>
                                                                                    </td>
                                                                                </tr>
                                                                            </tbody>
                                                                        </template>
                                                                    </v-simple-table>
                                                                </v-card-text>
                                                            </v-card>
                                                        </v-col>
                                                    </v-row>
                                                </v-card-text>
                                            </v-card>
                                        </template>
                                    </v-col>
                                </v-row>
                            </v-card-actions>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>
<script>
import NavBar from '../components/NavBar';
import SideBar from '../components/SideBar';
import _ from 'lodash';
import * as stateAPI from '../stateAPI';
import * as user_analytics from '../analytics/sendAnalyticsEvent';
import * as fetchAccountDetails from '../fetchAccountDetails';
import * as fetchNotifications from '../fetchNotifications';

export default {
    name: 'Account',

    components: { NavBar, SideBar },

    props: ['prop_usn', 'initPage'],

    computed: {
        theme() {
            return this.$vuetify.theme.dark ? 'dark' : 'light';
        }
    },

    watch: {
        defaultProjectionDate(val) {
            if (Date.parse(val) < Date.now()) {
                // TODO send notification when default projection date is reset
                return `${new Date().getFullYear()}-12-31`;
            }
        }
    },

    methods: {
        updateAccountDetails(element, new_value) {
            var tempConfig = _.cloneDeep(this.curConfig);
            tempConfig[element] = new_value;
            const query_params = {
                email: this.email,
                config: 1,
                new_config: JSON.stringify(tempConfig)
            };
            this.isLoading = true;
            fetchAccountDetails
                .updateAccountDetails(query_params)
                .then((result) => {
                    if (result != undefined && result.status == 200) {
                        if (element === 'aggregationStartDate') {
                            const dateSplit = new_value.split('-');
                            const start = new Date(
                                new Date(
                                    dateSplit[0],
                                    dateSplit[1] - 1,
                                    dateSplit[2]
                                ).setHours(5, 0, 0)
                            );
                            stateAPI.setStateProperty(this, 'startDate', start);
                            const today = new Date(
                                new Date().setHours(5, 0, 0)
                            );
                            const timePeriodToStore = (
                                (today.getTime() - start.getTime()) /
                                (24 * 60 * 60 * 1000)
                            ).toFixed(0);
                            stateAPI.setStateProperty(
                                this,
                                'storeTimePeriod',
                                timePeriodToStore
                            );
                        } else {
                            stateAPI.setStateProperty(this, element, new_value);
                        }
                        this.curConfig = tempConfig;
                        this.snackbarText = 'Settings updated successfully.';
                    } else {
                        this.aggregationStartDate = stateAPI.getStateProperty(
                            this,
                            'startDate'
                        );
                        this.defaultProjectionDate = stateAPI.getStateProperty(
                            this,
                            'defaultProjectionDate'
                        );
                        this.snackbarText =
                            'Error occurred while updating settings. Please try again. \n If error persists please contact an administator.';
                    }
                    this.snackbar = true;
                    this.isLoading = false;
                });
        },

        getAccountDetails() {
            const storedEmail = stateAPI.getStateProperty(this, 'email');
            const query_params = {
                email: storedEmail
            };
            this.isLoading = true;
            fetchAccountDetails
                .getAccountDetails(query_params)
                .then((result) => {
                    if (result != undefined && result.status == 200) {
                        const data = result.data.records[0];
                        this.firstName = this.capitalizeFirstLetter(
                            data.first_name
                        );
                        this.lastName = this.capitalizeFirstLetter(
                            data.last_name
                        );
                        this.phoneNumber = this.formatPhoneNumber(
                            data.phone_number
                        );
                        this.shortName = (
                            this.firstName[0] + this.lastName[0]
                        ).toUpperCase();
                        this.email = storedEmail;
                        this.isLoading = false;
                    }
                });
        },

        getCurrentConfig() {
            const storedEmail = stateAPI.getStateProperty(this, 'email');
            const query_params = {
                email: storedEmail,
                config: 1
            };
            this.isLoading = true;
            fetchAccountDetails
                .getAccountDetails(query_params)
                .then((result) => {
                    if (result != undefined && result.status == 200) {
                        const cfg = JSON.parse(result.data.records[0].config);
                        this.curConfig = cfg;
                        this.formFields.aggregationStartDate =
                            'aggregationStartDate' in cfg
                                ? cfg.aggregationStartDate
                                : `${new Date().getFullYear()}-01-01`;
                        this.formFields.defaultProjectionDate =
                            'defaultProjectionDate' in cfg
                                ? cfg.defaultProjectionDate
                                : `${new Date().getFullYear()}-12-31`;
                        this.isLoading = false;
                    }
                });
        },

        capitalizeFirstLetter(word) {
            return word.charAt(0).toUpperCase() + word.slice(1);
        },

        formatPhoneNumber(number) {
            var cleanedNumber = number.replace(/\D/g, '');
            var match = cleanedNumber.match(/^(\d{3})(\d{3})(\d{4})$/);
            if (match) {
                return '(' + match[1] + ') ' + match[2] + '-' + match[3];
            }
            return number;
        },

        allowedDates(dateSelected) {
            const date = new Date(new Date(dateSelected).setHours(5, 0, 0));
            const today = new Date(new Date().setHours(5, 0, 0));
            const timePeriod = (
                (today.getTime() - date.getTime()) /
                (24 * 60 * 60 * 1000)
            ).toFixed(0);
            return timePeriod > 2;
        },

        futureOnly(dateSelected) {
            const date = new Date(new Date(dateSelected).setHours(5, 0, 0));
            const today = new Date(new Date().setHours(5, 0, 0));
            const timePeriod = (
                (today.getTime() - date.getTime()) /
                (24 * 60 * 60 * 1000)
            ).toFixed(0);
            return timePeriod < 2;
        },

        saveChanges() {
            var tempConfig = _.cloneDeep(this.formFields);
            const query_params = {
                email: this.email,
                config: 1,
                new_config: JSON.stringify(tempConfig)
            };
            this.isLoading = true;
            fetchAccountDetails
                .updateAccountDetails(query_params)
                .then((result) => {
                    if (result != undefined && result.status == 200) {
                        for (const entry in tempConfig) {
                            if (entry !== 'aggregationStartDate') {
                                stateAPI.setStateProperty(
                                    this,
                                    entry,
                                    tempConfig[entry]
                                );
                            } else {
                                const dateSplit =
                                    tempConfig.aggregationStartDate.split('-');
                                const start = new Date(
                                    new Date(
                                        dateSplit[0],
                                        dateSplit[1] - 1,
                                        dateSplit[2]
                                    ).setHours(5, 0, 0)
                                );
                                stateAPI.setStateProperty(
                                    this,
                                    'startDate',
                                    start
                                );
                                const today = new Date(
                                    new Date().setHours(5, 0, 0)
                                );
                                const timePeriodToStore = (
                                    (today.getTime() - start.getTime()) /
                                    (24 * 60 * 60 * 1000)
                                ).toFixed(0);
                                stateAPI.setStateProperty(
                                    this,
                                    'storeTimePeriod',
                                    timePeriodToStore
                                );
                            }
                        }
                        this.curConfig = tempConfig;
                        this.snackbarText = 'Settings updated successfully.';
                    } else {
                        this.aggregationStartDate = stateAPI.getStateProperty(
                            this,
                            'startDate'
                        );
                        this.defaultProjectionDate = stateAPI.getStateProperty(
                            this,
                            'defaultProjectionDate'
                        );
                        this.snackbarText =
                            'Error occured while updating settings. Please try again. \n If error persists please contact an administator.';
                    }
                    this.snackbar = true;
                    this.isLoading = false;
                });
        },

        getSubscriptionStatuses() {
            this.isLoading = true;
            var query_params = {
                email: stateAPI.getStateProperty(this, 'email')
            };
            fetchNotifications
                .getSubscriptionStatus(query_params)
                .then((result) => {
                    if (result.length == 0) {
                        this.no_entry = true;
                    }

                    if (
                        stateAPI.getStateProperty(this, 'role') ==
                        result['customer_type']
                    ) {
                        for (const [key, __] of Object.entries(result)) {
                            if (key == 'email') {
                                continue;
                            }
                            if (result[key]['subscribed']) {
                                this.notification_switches.push(key);
                                this.switches.forEach((item) => {
                                    if (item.value == key) {
                                        item.subscribed = true;
                                        item.frequency = result[key][
                                            'frequency'
                                        ]
                                            .split(',')
                                            .map((x) => parseInt(x, 10));
                                        item.frequency_model = item.frequency;
                                    }
                                });
                            } else {
                                this.notification_switches.splice(
                                    this.notification_switches.indexOf(key),
                                    1
                                );
                            }
                        }
                    }

                    this.isLoading = false;
                });
        },

        modifySubscriptionStatus(key, subscribed) {
            var notification_key = key;
            var local_subscription_status =
                subscribed == null ? false : subscribed;
            var userId = stateAPI.getStateProperty(this, 'user_id');
            this.isLoading = true;
            if (this.no_entry) {
                console.log('Creating new!');
                this.createNewSubscriber(notification_key, userId);
            } else {
                console.log('Updating!');
                this.updateSubscriptionStatus(
                    notification_key,
                    local_subscription_status,
                    userId
                );
            }
        },

        createNewSubscriber(notification_key, shipperId) {
            var query_params = {
                email: stateAPI.getStateProperty(this, 'email'),
                notification_type: notification_key,
                shipperId: shipperId,
                customerType: stateAPI.getStateProperty(this, 'role')
            };
            fetchNotifications
                .createNewSubscriber(query_params)
                .then((result) => {
                    this.isLoading = false;
                    this.no_entry = false;
                    this.snackbarText = `You have successfully subscribed to ${notification_key.replace(
                        '_',
                        ' '
                    )} notifications`;
                    this.snackbar = true;
                });
        },

        updateSubscriptionStatus(
            notification_key,
            local_subscription_status,
            shipperId
        ) {
            var query_params = {
                email: stateAPI.getStateProperty(this, 'email'),
                notification_type: notification_key,
                sub_status: local_subscription_status,
                shipperId: shipperId
            };
            fetchNotifications
                .updateSubscriptionStatus(query_params)
                .then((result) => {
                    this.isLoading = false;
                    this.snackbarText = `You have successfully ${
                        !local_subscription_status ? 'un' : ''
                    }subscribed
        ${
            !local_subscription_status ? 'from' : 'to'
        } ${notification_key.replace('_', ' ')} notifications`;
                    this.snackbar = true;
                });
        },

        updateSubscriptionFrequency(item) {
            if (
                item.frequency_model == undefined ||
                item.frequency_model.length == 0
            ) {
                item.frequency_model = item.frequency;
            } else if (
                item.frequency.toString() != item.frequency_model.toString()
            ) {
                var query_params = {
                    email: stateAPI.getStateProperty(this, 'email'),
                    notification_type: item.value,
                    frequency: item.frequency_model.toString()
                };
                fetchNotifications
                    .updateSubscriptionFrequency(query_params)
                    .then((result) => {
                        this.isLoading = false;
                        this.snackbarText = `Frequency successfully updated`;
                        this.snackbar = true;
                        item.frequency = item.frequency_model;
                    });
            }
        },

        toggleDarkMode() {
            stateAPI.setStateProperty(
                this,
                'darkMode',
                this.formFields.darkMode
            );
            this.$vuetify.theme.dark = this.formFields.darkMode;
            this.saveChanges();
        }
    },

    beforeMount() {
        this.switches = this.all_switches.filter((item) =>
            item.type.includes(this.role)
        );
        this.getAccountDetails();
        this.getCurrentConfig();
        this.getSubscriptionStatuses();
        // Send pageView analytics event
        const email = stateAPI.getStateProperty(this, 'email');
        const company = stateAPI.getStateProperty(this, 'company');
        const role = stateAPI.getStateProperty(this, 'role');
        user_analytics.sendEvent(email, company, role, 'pageView', {
            pageSource: 'Account'
        });
    },

    mounted: function () {
        if (this.initPage != null && this.initPage) {
            this.selectedItem = 1;
        }
    },

    data: function () {
        return {
            menu1: false,
            menu2: false,
            usn: 'TESTING',
            selectedItem: 2,
            email: '',
            firstName: '',
            lastName: '',
            phoneNumber: '',
            shortName: '',
            role: stateAPI.getStateProperty(this, 'role'),
            snackbar: false,
            snackbarText: '',
            snackBarTimeout: 1500,
            items: [
                { text: 'Profile', icon: 'mdi-account-circle' },
                { text: 'Settings', icon: 'mdi-cog' },
                { text: 'Notifications', icon: 'mdi-bell' }
            ],
            no_entry: false,
            notification_switches: [],
            switches: [],
            all_switches: [
                {
                    name: 'High Margin',
                    value: 'high_margin',
                    description:
                        'Notifies you when a high margin load is detected.',
                    frequency: [0, 1, 2, 3, 4],
                    frequency_model: [0, 1, 2, 3, 4],
                    type: ['shipper'],
                    subscribed: false
                },
                {
                    name: 'Customer Direct',
                    value: 'customer_direct',
                    description:
                        'Notifies you when a customer direct load is late.',
                    frequency: [0, 1, 2, 3, 4],
                    frequency_model: [0, 1, 2, 3, 4],
                    type: ['broker', 'shipper'],
                    subscribed: false
                }
            ], // TODO (P2): add subscriber type constraints to db and have lambda access them
            isLoading: true,
            init_page: this.initPage,

            // Input fields
            formFields: {
                aggregationStartDate: '',
                defaultProjectionDate: '',
                darkMode: this.$vuetify.theme.dark
            },

            // Config object in db
            curConfig: null,
            notif_frequency: '',
            days: [
                { text: 'Mon', value: 0 },
                { text: 'Tues', value: 1 },
                { text: 'Wed', value: 2 },
                { text: 'Thurs', value: 3 },
                { text: 'Fri', value: 4 }
            ]
        };
    }
};
</script>
<style>
.notificationDataTable {
    background-color: var(--v-dataTableBackground-base) !important;
    color: var(--v-dataTableText-base) !important;
}
</style>
