<template>
    <div style="width: 100%">
        <apexchart
            :height="height"
            :type="type"
            :options="options"
            :series="series"
            :ref="ref"
        ></apexchart>
    </div>
</template>
<script>
import * as format from '../formatShipmentData';
import getConfig from '../configs/graphConfig';
import * as utils from '../utils';
import _ from 'lodash';
export default {
    name: 'Graph',
    props: ['rawData', 'graphName', 'timeRange', 'isBroker'],

    computed: {
        options: {
            get() {
                return _.cloneDeep(this.config.graphOptions);
            },
            set(newVal) {
                // do not update options if reference cannot be found
                if (this.$refs[this.ref] != undefined) {
                    this.$refs[this.ref].updateOptions(_.cloneDeep(newVal));
                }
            }
        },
        type() {
            let cfg = this.config;
            return cfg.type;
        },
        height() {
            let cfg = this.config;
            return typeof cfg?.height !== 'undefined' ? cfg.height : 220;
        },
        isDark() {
            return this.$vuetify.theme.dark;
        }
    },

    watch: {
        graphName(val) {
            this.config = getConfig(val, this);
            this.updateGraph();
        },

        rawData(val) {
            this.prevRaw = val;
            this.updateGraph();
        },

        isDark(val) {
            this.options = this.deepMerge(this.options, {
                chart: {
                    foreColor: this.isDark ? '#E1E1E1' : '#000000',
                    background: this.isDark
                        ? this.$vuetify.theme.themes.dark.cardBackgroundColor
                        : this.$vuetify.theme.themes.light.cardBackgroundColor
                },
                theme: { mode: this.isDark ? 'dark' : 'light' }
            });
        }
    },

    methods: {
        updateGraph() {
            this.series = [];
            var optionAdd = {};
            if (this.type == 'bar' && this.rawData != null) {
                const eligibleDays = this.rawData.flatMap(
                    (item) => item.day_of_week
                );
                this.dayCategories = this.allCategoriesAbbv.filter(
                    (item, index) => {
                        return eligibleDays.includes(index + 1);
                    }
                );
                this.dayCategoriesFull = this.allCategoriesFull.filter(
                    (item, index) => {
                        return eligibleDays.includes(index + 1);
                    }
                );
            }
            for (const [name, val] of Object.entries(this.config.toDraw)) {
                this.extractData(
                    name,
                    val.decimals,
                    val?.dataName,
                    this.config?.customReader
                );
                if (val.options) {
                    this.extractOptions(val.options, optionAdd);
                }
            }
            this.options = this.deepMerge(this.options, optionAdd);
        },

        extractOptions: function (object, optionDrill) {
            // Recursively insert object into optionDrill, creating arrays for duplicate keys
            Object.keys(object).forEach((key) => {
                let item = object[key];
                if (Array.isArray(optionDrill[key])) {
                    optionDrill[key].push(item);
                } else if (typeof item === 'object') {
                    if (optionDrill[key] === undefined) {
                        optionDrill[key] = {};
                    }
                    this.extractOptions(item, optionDrill[key]);
                } else if (optionDrill[key] === undefined) {
                    optionDrill[key] = [item];
                }
            });
        },

        deepMerge: function (target, source) {
            // Returns a recursively merged object that share key structures, overwriting target with source
            var output = target;
            Object.keys(source).forEach((key) => {
                if (
                    typeof target[key] !== 'object' ||
                    Array.isArray(target[key])
                ) {
                    output[key] = source[key];
                } else {
                    output[key] = this.deepMerge(output[key], source[key]);
                }
            });
            return output;
        },

        extractData: function (
            name,
            avgDecimal = 0,
            nameOverride,
            readOverride
        ) {
            // extracts rawData into this.series
            const data = this.rawData;
            const dataType = this.type;
            var nameVal = nameOverride ? nameOverride : name.toLowerCase();
            var shaped = [];
            if (readOverride) {
                readOverride(data, nameVal, name);
                return {};
            } else if (['average', 'avg'].includes(name.toLowerCase())) {
                // Average line
                let average = format.formatDecimal(
                    data[0][nameVal],
                    avgDecimal
                );
                data.forEach((element) =>
                    shaped.push({
                        x: element.week,
                        y: average
                    })
                );
                shaped.sort((a, b) => (a.x > b.x ? 1 : -1));
            } else if (
                data != null &&
                data[0] != undefined &&
                nameVal in data[0]
            ) {
                if (dataType === 'line' || dataType === 'area') {
                    // Trend line
                    data.forEach((element) =>
                        shaped.push({
                            x: element.week,
                            y: parseFloat(element[nameVal])
                        })
                    );
                    shaped.sort((a, b) => (a.x > b.x ? 1 : -1));
                } else if (dataType === 'bar') {
                    data.forEach((element) => shaped.push(element[nameVal]));
                }
            } else {
                // Type not found
                console.log('Invalid trend graph line type', nameVal);
                return {};
            }
            this.series.push({
                name: name,
                data: shaped
            });
        },

        getGraphTooltipDate(value) {
            var options = { month: 'short', day: 'numeric' };
            var day_ms = 1000 * 60 * 60 * 24;
            const week_date = new Date(value + day_ms).toLocaleDateString(
                'en-us',
                options
            );
            if (this.timeRange > utils.DEFAULT_DAYS) {
                var week_offset_date = new Date(
                    value + 7 * day_ms
                ).toLocaleDateString('en-us', options);
                return week_date + ' - ' + week_offset_date;
            }
            return week_date;
        }
    },

    beforeMount() {
        window.component = this;
    },

    data: function () {
        return {
            isLaneData: !this.isBroker,
            config: getConfig(this.graphName, this),
            series: [],
            toggled: [],
            ref: this.graphName,
            dayCategories: [],
            dayCategoriesFull: [],
            allCategoriesAbbv: [
                'Sun',
                'Mon',
                'Tues',
                'Wed',
                'Thurs',
                'Fri',
                'Sat'
            ],
            allCategoriesFull: [
                'Sunday',
                'Monday',
                'Tuesday',
                'Wednesday',
                'Thursday',
                'Friday',
                'Saturday'
            ]
        };
    }
};
</script>
